<template>
  <div class="steps">
    <el-steps :align-center="true">
      <el-step icon="el-icon-s-order">
        <template slot="icon">
          <span class="active">
            <i class="el-icon-s-order" />
            <span>{{ $t("enrollment.list.title") }}</span>
          </span>
        </template>
        <template slot="title">
          <slot name="enrollStep" />
        </template>
      </el-step>
      <el-step icon="el-icon-s-order">
        <template slot="icon">
          <span :class="hasLog ? 'active' : ''">
            <i class="el-icon-edit" />
            <span>{{ $t("enrollment.detail.log") }}</span>
          </span>
        </template>
        <template slot="title">
          <span @click="$emit('toggleLogDialog', true)">
            <i class="el-icon-chat-line-square action-icon" />
          </span>
        </template>
      </el-step>
      <el-step icon="el-icon-s-order">
        <template slot="icon">
          <span :class="hasContract ? 'active' : ''">
            <i class="el-icon-edit" />
            <span>{{ $t("enrollment.detail.contract") }}</span>
          </span>
        </template>
        <template slot="title">
          <ContractStatus
            :contractCreatedAt="enrollment.contract_created_at"
            :contractSignedAt="enrollment.contract_signed_at"
            :hasContract="hasContract"
            :hasSignedContract="hasSignedContract"
            @openContractDialog="
              () => {
                $emit('toggleContractDialog', true);
              }
            "
          />
        </template>
      </el-step>
      <el-step icon="el-icon-s-order">
        <template slot="icon">
          <span :class="isPaid ? 'active' : ''">
            <i class="el-icon-money" />
            <span>{{ $t("enrollment.list.paymentStatus") }}</span>
            <span
              >({{
                enrollment.need_pay_first
                  ? $t("enrollment.detail.payList[1]")
                  : $t("enrollment.detail.payList[0]")
              }})</span
            >
          </span>
          <div v-if="isEdit">
            (
            <el-switch
              @change="$emit('setPayFirstOrLater')"
              v-model="enrollment.need_pay_first"
              :inactive-text="$t('enrollment.detail.payList[0]')"
              :inactive-value="0"
              :active-text="$t('enrollment.detail.payList[1]')"
              :active-value="1"
            />
            )
          </div>
        </template>
        <template slot="title">
          <template v-if="isPaid">
            <router-link
              v-if="enrollment.receivable_order"
              :to="{
                name: 'ReceivableView',
                params: { receivableId: enrollment.receivable_order.id }
              }"
            >
              <span class="action-icon">
                <i class="fas fa-check" />
              </span>
            </router-link>
            <el-tooltip
              v-else-if="enrollment.order"
              :content="instant.formatDate(enrollment.order.mark_paid_at)"
              placement="top"
              popper-class="tooltipColor"
            >
              <router-link
                v-if="enrollment.order"
                :to="{
                  name: 'BillView',
                  params: { billId: enrollment.order.id }
                }"
              >
                <span class="action-icon">
                  <i class="fas fa-check" />
                </span>
              </router-link>
            </el-tooltip>
            <el-tooltip
              v-else
              content="此為舊資料轉入，無對應帳單"
              placement="top"
              popper-class="tooltipColor"
            >
              <span class="action-icon">
                <i class="fas fa-check" />
              </span>
            </el-tooltip>
          </template>
          <el-tooltip
            v-else-if="!isPaid && enrollment.receivable_order"
            :content="
              instant.formatDate(enrollment.receivable_order.created_at)
            "
            placement="top"
            popper-class="tooltipColor"
          >
            <router-link
              :to="{
                name: 'ReceivableView',
                params: { receivableId: enrollment.receivable_order.id }
              }"
            >
              <span style="color: red">分期付款中</span>
            </router-link>
          </el-tooltip>
          <!-- <router-link
            v-else-if="!isPaid"
            :to="{
              name: 'createReceivableWithEnrollment',
              params: { id: enrollment.id }
            }"
          >
            <i class="el-icon-plus" />
          </router-link> -->
          <router-link
            v-else-if="!isPaid"
            :to="{
              name: 'createBillWithEnrollment',
              params: { id: enrollment.id }
            }"
          >
            <i class="el-icon-plus" />
          </router-link>
        </template>
      </el-step>
      <el-step icon="el-icon-s-order">
        <template slot="icon">
          <span :class="hasPlacementTest.user_exam_id ? 'active' : ''">
            <i class="el-icon-tickets" />
            <span>{{ $t("enrollment.detail.placementTest") }}</span>
          </span>
        </template>
        <template slot="title">
          <ExamStatus
            :enrollment="enrollment"
            :hasPlacementTest="hasPlacementTest"
            @toggleAssignTestDialog="$emit('toggleAssignTestDialog', true)"
          />
        </template>
      </el-step>
      <el-step icon="el-icon-s-order">
        <template slot="icon">
          <span :class="isFinisheClassPlacement ? 'active' : ''">
            <i class="el-icon-folder" />
            <span>{{ $t("enrollment.detail.class") }}</span>
          </span>
        </template>
        <template slot="title">
          <span v-if="hasCourse">
            <router-link
              :to="{
                name: 'classDetail',
                params: { classId: sessionClass.id }
              }"
              :key="sessionClass.id"
              v-for="sessionClass in filterNotNull(enrollment.session_classes)"
            >
              {{ sessionClass.title }}
            </router-link>
          </span>
          <!-- <router-link v-if="hasCourse" :to="{ name: 'Arrange' }">
            <i class="fas fa-edit action-icon" />
          </router-link> -->
          <el-popover placement="top" v-model="visible">
            <p>選擇報名流程</p>
            <p>
              <router-link :to="{ name: 'Arrange' }">
                <el-button
                  type="primary"
                  size="mini"
                  @click="visible = false"
                  style="width:100%"
                >
                  分配團班
                </el-button>
              </router-link>
            </p>
            <p>
              <router-link
                :to="{
                  name: 'privateCreate',
                  params: {
                    privateType: 'private-lessons'
                  },
                  query: {
                    enrolled_session_id: enrollment.id
                  }
                }"
              >
                <el-button
                  type="primary"
                  size="mini"
                  @click="visible = false"
                  style="width:100%"
                >
                  建立家教課程
                </el-button>
              </router-link>
            </p>
            <p>
               <router-link
                  :to="{
                    name: 'privateCreate',
                    params: {
                      privateType: 'counseling'
                    },
                    query: {
                      enrolled_session_id: enrollment.id
                    }
                  }"
                >
              <el-button
                type="primary"
                size="mini"
                @click="visible = false"
                style="width:100%"
              >
               
                  建立升學輔導
                  </el-button>
                </router-link>
              
            </p>
            <span slot="reference"><i class="fas fa-edit action-icon"/></span>
          </el-popover>
        </template>
      </el-step>
    </el-steps>
  </div>
</template>

<script>
import { instant } from "@ivy-way/material";
import ExamStatus from "@/views/home/enrollment/steps/ExamStatus";
import ContractStatus from "@/views/home/enrollment/steps/ContractStatus";

export default {
  components: { ExamStatus, ContractStatus },
  props: [
    "enrollment",
    "hasCourse",
    "hasContract",
    "hasSignedContract",
    "hasLog",
    "isPaid",
    "hasPlacementTest",
    "isFinisheClassPlacement",
    "isEdit"
  ],
  computed: {
    instant() {
      return instant;
    }
  },
  methods: {
    filterNotNull(sessionClasses) {
      return sessionClasses.filter(sessionClass => sessionClass);
    }
  }
};
</script>

<style scoped>
.active {
  color: #42a16a;
}

::v-deep .is-icon {
  width: 250px;
}

@media only screen and (min-width: 992px) {
  .steps-buttons >>> .el-col-md-4-8 {
    width: 20%;
  }
}

.steps {
  margin-bottom: 15px;
}
</style>

<template>
  <el-form
    @submit.prevent.native="updateEnrollment"
    :model="enrollmentForm"
    :validate-on-rule-change="false"
    ref="enrollmentForm"
    label-position="left"
    class="ivy-block-form-item"
  >
    <!-- 升學輔導 -->
    <ul v-if="(enrolled_session.mentoring_courses || []).length > 0">
      <li
        v-for="(mentoring_course, index) in enrolled_session.mentoring_courses"
        :key="index"
      >
        <span v-if="mentoring_course == 1">
          升學輔導 Mentoring (7-11th Grade)
        </span>
        <span v-if="mentoring_course == 2">
          Year-Round Mentor
        </span>
      </li>
    </ul>
    <!-- 家教課 -->
    <ul
      v-if="enrolled_session.custom_online || enrolled_session.custom_offline"
    >
      <li v-if="enrolled_session.custom_online">
        <!-- {{ tutorList[0] }}： -->
        {{ enrolled_session.custom_online }}
      </li>
      <li v-if="enrolled_session.custom_offline">
        <!-- {{ tutorList[1] }}： -->
        {{ enrolled_session.custom_offline }}
      </li>
    </ul>
    <!-- 想上的課程 -->
    <el-form-item
      :label="$t('enrollment.detail.enroll')"
      prop="course_session_id"
    >
      <el-select
        :value="enrollmentForm.course_session_id"
        @change="
          course_session_id => {
            this.resetEnrolledCourse(course_session_id);
            setEnrollment({ course_session_id });
          }
        "
        :placeholder="$t('validation.selectRequired')"
        style="width: 400px"
        filterable
        :disabled="!editable"
      >
        <el-option
          v-for="session in sessionList"
          :key="session.id"
          :label="helper.displayI18nText($i18n.locale, session.title)"
          :value="session.id"
        ></el-option>
      </el-select>
      <div style="display: inline-block" v-if="isPrivateLesson">
        <router-link
          v-if="isExistPrivateLesson"
          :to="{
            name: isCounseling
              ? 'counselingClassesDetail'
              : 'privateClassesDetail',
            params: { id: enrolled_session.student_class_tickets[0].class_id }
          }"
          style="margin: 0px 20px"
        >
          <el-button plain type="success" icon="el-icon-view">
            查看家教（客製）課
          </el-button>
        </router-link>
        <el-button
          v-else
          @click="createCustomLesson"
          plain
          type="success"
          icon="el-icon-plus"
          style="margin: 0px 20px"
          >新增為家教（客製）課
        </el-button>
      </div>
      <!-- <el-button
        v-if="editable && enrollmentForm.course_session_id"
        @click="resetEnrolledCourse"
        type="danger"
        style="margin: 0px 20px"
      >
        清空所有分班的报名
      </el-button> -->
    </el-form-item>
    <!-- 是否上過類似課程 -->
    <el-form-item
      :label="$t('enrollment.step3.similarClass.question')"
      :rules="commonRules.radio"
      prop="had_similar_course"
      required
    >
      <el-radio-group
        :value="enrollmentForm.had_similar_course"
        @input="had_similar_course => setEnrollment({ had_similar_course })"
      >
        <el-radio :label="1">
          {{ $t("enrollment.step3.radioOptions")[0] }}
        </el-radio>
        <el-radio :label="0">
          {{ $t("enrollment.step3.radioOptions")[1] }}
        </el-radio>
      </el-radio-group>
      <div class="clearfix"></div>
      <!-- 過去的上課經驗 -->
      <div v-if="enrollmentForm.had_similar_course" class="ml-3">
        <el-form-item
          :label="$t('enrollment.step3.similarClass.whereAndWhen')"
          :rules="commonRules.input"
          prop="past_course_info"
          required
          class="mb-3"
        >
          <el-input
            :value="enrollmentForm.past_course_info"
            @input="past_course_info => setEnrollment({ past_course_info })"
            required
            :disabled="!editable"
          ></el-input>
        </el-form-item>
        <el-form-item :label="$t('enrollment.step3.similarClass.how')">
          <el-input
            type="textarea"
            :value="enrollmentForm.past_course_exp"
            @input="past_course_exp => setEnrollment({ past_course_exp })"
            :rows="5"
            :disabled="!editable"
          ></el-input>
        </el-form-item>
        <p>{{ $t("enrollment.step3.similarClass.notes") }}</p>
      </div>
    </el-form-item>
    <!-- 中文是否有幫助 -->
    <el-form-item
      :label="$t('enrollment.step3.mandarin.question')"
      :rules="commonRules.radio"
      prop="will_mandarin_help"
      required
    >
      <el-radio-group
        :value="enrollmentForm.will_mandarin_help"
        @input="will_mandarin_help => setEnrollment({ will_mandarin_help })"
      >
        <el-radio
          v-for="(option, index) in mandarinOptions"
          :key="index"
          :label="index"
          class="d-block ml-3 mt-3"
          :disabled="!editable"
        >
          {{ option }}
        </el-radio>
      </el-radio-group>
    </el-form-item>
    <!-- 如何得知 Ivy Way -->
    <el-form-item
      :label="$t('enrollment.step3.knowUs.question')"
      :rules="commonRules.input"
      prop="know_us"
      required
    >
      <el-input
        :value="enrollmentForm.know_us"
        @input="know_us => setEnrollment({ know_us })"
        :placeholder="$t('enrollment.step3.knowUs.placeholder')"
        required
        :disabled="!editable"
      ></el-input>
    </el-form-item>
    <!-- 是否為舊生推薦 -->
    <el-form-item
      :label="$t('enrollment.step3.refer.question-main')"
      :rules="commonRules.radio"
      prop="is_student_referral"
      required
    >
      <el-radio-group
        :value="enrollmentForm.is_student_referral"
        @input="is_student_referral => setEnrollment({ is_student_referral })"
      >
        <el-radio :label="1">
          {{ $t("enrollment.step3.radioOptions")[0] }}
        </el-radio>
        <el-radio :label="0">
          {{ $t("enrollment.step3.radioOptions")[1] }}
        </el-radio>
      </el-radio-group>
      <div class="clearfix"></div>
      <!-- 舊生資料 -->
      <div v-if="enrollmentForm.is_student_referral" class="ml-3">
        <el-form-item
          :label="$t('enrollment.step3.refer.question-sub')"
          required
        >
          <el-input
            type="textarea"
            :value="enrollmentForm.referrer"
            @input="referrer => setEnrollment({ referrer })"
            :rows="3"
            :disabled="!editable"
          ></el-input>
        </el-form-item>
        <p>{{ $t("enrollment.step3.refer.notes") }}</p>
      </div>
    </el-form-item>
    <!-- 是否團報 -->
    <el-form-item
      :label="$t('enrollment.step3.together.question-main')"
      :rules="commonRules.radio"
      prop="enroll_with_others"
      required
    >
      <el-radio-group
        :value="enrollmentForm.enroll_with_others"
        @input="enroll_with_others => setEnrollment({ enroll_with_others })"
      >
        <el-radio :label="1">
          {{ $t("enrollment.step3.radioOptions")[0] }}
        </el-radio>
        <el-radio :label="0">
          {{ $t("enrollment.step3.radioOptions")[1] }}
        </el-radio>
      </el-radio-group>
      <div class="clearfix"></div>
      <!-- 團報生資料 -->
      <div v-if="enrollmentForm.enroll_with_others" class="ml-3">
        <el-form-item
          :label="$t('enrollment.step3.together.question-sub')"
          required
        >
          <el-input
            type="textarea"
            :value="enrollmentForm.other_students"
            @input="other_students => setEnrollment({ other_students })"
            :rows="5"
            :disabled="!editable"
          ></el-input>
        </el-form-item>
        <p>{{ $t("enrollment.step3.together.notes") }}</p>
      </div>
    </el-form-item>
    <!-- 如何付費 -->
    <el-form-item
      :label="$t('enrollment.step3.pay.question')"
      :rules="commonRules.radio"
      prop="currency"
      required
    >
      <el-radio-group
        :value="enrollmentForm.payment_method"
        @input="
          payment_method =>
            setEnrollment({
              payment_method,
              currency: 1
            })
        "
        class="ml-3"
      >
        <el-radio
          v-for="(pay, index) in payOptions"
          :key="index"
          :label="index + 1"
          class="d-block mt-3"
          :disabled="!editable"
        >
          {{ pay.way }}
          <div class="clearfix"></div>
          <!-- 幣別 -->
          <el-radio-group
            v-if="enrollmentForm.payment_method === index + 1"
            :value="enrollmentForm.currency"
            @input="currency => setEnrollment({ currency })"
            class="ml-3"
          >
            <el-radio
              v-for="(currency, key) in payOptions[index].currency"
              :key="key"
              :label="Number(key)"
              class="d-block mt-3"
              :disabled="!editable"
            >
              {{ currency }}
            </el-radio>
          </el-radio-group>
        </el-radio>
      </el-radio-group>
    </el-form-item>
    <!-- 補充 -->
    <el-form-item :label="$t('enrollment.step3.other.question')">
      <el-input
        type="textarea"
        :value="enrollmentForm.other_info"
        @input="other_info => setEnrollment({ other_info })"
        :placeholder="$t('enrollment.step3.other.placeholder')"
        :rows="5"
        :disabled="!editable"
      ></el-input>
    </el-form-item>
    <el-form-item :label="$t('enrollment.detail.paymentSetting')">
      <el-switch
        v-model="enrolled_session.need_pay_first"
        :value="enrollmentForm.need_pay_first"
        @change="
          need_pay_first => {
            setEnrollment({ need_pay_first });
            $emit('setPayFirstOrLater');
          }
        "
        :inactive-text="$t('enrollment.detail.payList[0]')"
        :inactive-value="0"
        :active-text="$t('enrollment.detail.payList[1]')"
        :active-value="1"
        :disabled="!editable"
      ></el-switch>
    </el-form-item>
    <!-- 儲存 -->
    <el-form-item class="text-right">
      <el-button native-type="submit" type="primary">
        {{ $t("enrollment.detail.saveButton") }}
      </el-button>
    </el-form-item>
  </el-form>
</template>

<script>
import { helper } from "@ivy-way/material";
import formMixin from "@/mixins/form";
import profileTest from "@/mixins/profileTest";
import courseApi from "@/apis/course";
import { EnumPrivateSessions } from "@/enums";

export default {
  props: {
    enrollmentId: {
      type: String,
      default: null
    },
    editable: {
      type: Boolean,
      default: false
    },
    enrollmentForm: {
      type: Object,
      default: () => ({})
    },
    enrolled_session: {
      type: Object,
      default: () => ({})
    },
    isPrivateLesson: {
      type: Boolean,
      default: false
    },
    isExistPrivateLesson: {
      type: Boolean,
      default: false
    }
  },
  mixins: [formMixin, profileTest],
  computed: {
    tutorList() {
      return this.$t("enrollment.step3.tutorList");
    },
    helper() {
      return helper;
    },
    payOptions() {
      return this.$t("enrollment.step3.pay.options");
    },
    mandarinOptions() {
      return this.$t("enrollment.step3.mandarin.options");
    },
    isCounseling() {
      return EnumPrivateSessions.counselingIds.includes(
        this.enrollmentForm.course_session_id
      );
    }
  },
  data() {
    return {
      sessionList: []
    };
  },
  async created() {
    const { course_sessions } = await courseApi.fetchSessions({
      is_visible: 1
    });
    this.sessionList = course_sessions;
  },
  methods: {
    createCustomLesson() {
      let privateType = "private-lessons";
      if (this.isCounseling) {
        privateType = "counseling";
      }
      this.$router.push({
        name: "privateCreate",
        params: {
          privateType
        },
        query: {
          enrolled_session_id: this.enrollmentId
        }
      });
    },
    setEnrollment(enrollment) {
      this.$emit("setEnrollment", enrollment);
    },
    async resetEnrolledCourse(course_session_id) {
      try {
        await courseApi.resetEnrolledCourse(
          this.enrollmentId,
          course_session_id
        );
        // this.$router.go(0);
        // this.$message.success(this.$t("message.update_success"));
      } catch (e) {
        this.$message.error(this.$t("message.something_went_wrong"));
      }
    },
    async updateEnrollment() {
      let valid = await this.validateForm(this.$refs["enrollmentForm"]);
      if (!valid) {
        return;
      }

      this.$emit("updateEnrollment", false);
    }
  }
};
</script>

<style lang="scss" scoped></style>

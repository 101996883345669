<template>
  <div>
    <el-tag class="userTag" :key="tag.id" v-for="tag in tags">
      <i class="fas fa-tag" />
      {{ helper.displayI18nText($i18n.locale, tag.name) }}
    </el-tag>
  </div>
</template>

<script>
import { helper } from "@ivy-way/material";

export default {
  props: ["tags"],
  computed: {
    helper() {
      return helper;
    }
  }
};
</script>

<style scoped>
.userTag {
  margin-right: 8px;
  background-color: #ecf5ff;
  color: #409eff;
  border: 1px solid #d9ecff;
}
</style>

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"steps"},[_c('el-steps',{attrs:{"align-center":true}},[_c('el-step',{attrs:{"icon":"el-icon-s-order"}},[_c('template',{slot:"icon"},[_c('span',{staticClass:"active"},[_c('i',{staticClass:"el-icon-s-order"}),_c('span',[_vm._v(_vm._s(_vm.$t("enrollment.list.title")))])])]),_c('template',{slot:"title"},[_vm._t("enrollStep")],2)],2),_c('el-step',{attrs:{"icon":"el-icon-s-order"}},[_c('template',{slot:"icon"},[_c('span',{class:_vm.hasLog ? 'active' : ''},[_c('i',{staticClass:"el-icon-edit"}),_c('span',[_vm._v(_vm._s(_vm.$t("enrollment.detail.log")))])])]),_c('template',{slot:"title"},[_c('span',{on:{"click":function($event){return _vm.$emit('toggleLogDialog', true)}}},[_c('i',{staticClass:"el-icon-chat-line-square action-icon"})])])],2),_c('el-step',{attrs:{"icon":"el-icon-s-order"}},[_c('template',{slot:"icon"},[_c('span',{class:_vm.hasContract ? 'active' : ''},[_c('i',{staticClass:"el-icon-edit"}),_c('span',[_vm._v(_vm._s(_vm.$t("enrollment.detail.contract")))])])]),_c('template',{slot:"title"},[_c('ContractStatus',{attrs:{"contractCreatedAt":_vm.enrollment.contract_created_at,"contractSignedAt":_vm.enrollment.contract_signed_at,"hasContract":_vm.hasContract,"hasSignedContract":_vm.hasSignedContract},on:{"openContractDialog":() => {
              _vm.$emit('toggleContractDialog', true);
            }}})],1)],2),(_vm.needToPayFirst || _vm.isEdit)?_c('el-step',{attrs:{"icon":"el-icon-s-order"}},[_c('template',{slot:"icon"},[_c('span',{class:_vm.isPaid ? 'active' : ''},[_c('i',{staticClass:"el-icon-money"}),_c('span',[_vm._v(_vm._s(_vm.$t("enrollment.list.paymentStatus")))]),_c('span',[_vm._v("("+_vm._s(_vm.enrollment.need_pay_first ? _vm.$t("enrollment.detail.payList[1]") : _vm.$t("enrollment.detail.payList[0]"))+")")])]),(_vm.isEdit)?_c('div',[_vm._v(" ( "),_c('el-switch',{attrs:{"inactive-text":_vm.$t('enrollment.detail.payList[0]'),"inactive-value":0,"active-text":_vm.$t('enrollment.detail.payList[1]'),"active-value":1},on:{"change":function($event){return _vm.$emit('setPayFirstOrLater')}},model:{value:(_vm.enrollment.need_pay_first),callback:function ($$v) {_vm.$set(_vm.enrollment, "need_pay_first", $$v)},expression:"enrollment.need_pay_first"}}),_vm._v(" ) ")],1):_vm._e()]),_c('template',{slot:"title"},[(_vm.isPaid && !_vm.enrollment.order)?_c('el-tooltip',{attrs:{"content":"此為舊資料轉入，無對應帳單","placement":"top","popper-class":"tooltipColor"}},[_c('router-link',{attrs:{"to":{
              name: 'billView',
              params: { billId: _vm.enrollment.order.id }
            }}},[_c('i',{staticClass:"fas fa-check action-icon"})])],1):_vm._e(),(_vm.parent)?_c('div',[_c('router-link',{attrs:{"to":{
              name: 'Balance',
              query: { username: _vm.parent.basic_info.username }
            }}},[_vm._v(" "+_vm._s(_vm.addCurrencySymbol( _vm.parent.basic_info.account_balance, _vm.parent.basic_info.account_balance_currency ))+" ")]),_c('router-link',{attrs:{"to":{
              name: 'FinanceAdd',
              query: {
                parent: _vm.parent.basic_info.username
              }
            }}},[_vm._v(" （+ 儲值） ")])],1):_c('span',[_vm._v(" 此學生無對應家長 ")])],1)],2):_vm._e(),_c('el-step',{attrs:{"icon":"el-icon-s-order"}},[_c('template',{slot:"icon"},[_c('span',{class:_vm.hasPlacementTest.user_exam_id ? 'active' : ''},[_c('i',{staticClass:"el-icon-tickets"}),_c('span',[_vm._v(_vm._s(_vm.$t("enrollment.detail.placementTest")))])])]),_c('template',{slot:"title"},[_c('ExamStatus',{attrs:{"enrollment":_vm.enrollment,"hasPlacementTest":_vm.hasPlacementTest},on:{"toggleAssignTestDialog":function($event){return _vm.$emit('toggleAssignTestDialog', true)}}})],1)],2),_c('el-step',{attrs:{"icon":"el-icon-s-order"}},[_c('template',{slot:"icon"},[_c('span',{class:_vm.isFinisheClassPlacement ? 'active' : ''},[_c('i',{staticClass:"el-icon-folder"}),_c('span',[_vm._v(_vm._s(_vm.$t("enrollment.detail.private")))])])]),_c('template',{slot:"title"},[(!_vm.isExistPrivateLesson)?_c('el-tooltip',{attrs:{"content":_vm.$t('enrollment.steps.message.createPrivateLesson'),"placement":"top","popper-class":"tooltipColor"}},[_c('router-link',{attrs:{"to":{
              name: 'privateCreate',
              params: {
                privateType: _vm.privateType
              },
              query: {
                enrolled_session_id: _vm.enrollment.id
              }
            }}},[_c('i',{staticClass:"el-icon-plus"})])],1):_vm._e(),(_vm.isExistPrivateLesson)?_c('div',[_c('router-link',{attrs:{"to":{
              path: _vm.isExistTeacher
                ? `/private/${_vm.privateType}/${_vm.enrollment.student_class_tickets[0].class_id}/detail`
                : `/private/${_vm.privateType}/link-teacher/${_vm.enrollment.student_class_tickets[0].class_id}`
            }}},[_vm._v(" "+_vm._s(_vm.lessonTitle)+" ")])],1):_vm._e()],1)],2)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
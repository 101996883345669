<template>
  <div style="display:flex">
    <div style="margin:10px 0 0 10px">
      <el-tooltip content="Back To List" placement="top">
        <el-button
          plain
          type="primary"
          icon="el-icon-back"
          size="small"
          @click="
            () => {
              $router.push({ name: 'EnrollmentList' });
            }
          "
        />
      </el-tooltip>
      <el-tooltip content="Mark As Unread" placement="top">
        <el-button
          plain
          type="primary"
          icon="el-icon-message"
          size="small"
          @click="markUnread"
        />
      </el-tooltip>
      <el-tooltip content="Change Course" placement="top">
        <el-button
          @click="() => $emit('changeEdit')"
          plain
          type="success"
          icon="el-icon-refresh"
          size="small"
        ></el-button>
      </el-tooltip>
      <el-tooltip content="Delete" placement="top">
        <el-button
          plain
          type="primary"
          icon="el-icon-delete-solid"
          size="small"
          @click="deleteEnrollment"
        />
      </el-tooltip>
      <el-tooltip content="Cancel Editing" placement="top" v-if="isEdit">
        <el-button
          @click="() => $emit('colseEditMode')"
          plain
          type="danger"
          icon="el-icon-error"
          size="small"
        ></el-button>
      </el-tooltip>
      <el-tooltip content="Edit" placement="top" v-else>
        <el-button
          @click="() => $emit('changeEdit')"
          plain
          type="success"
          icon="el-icon-edit-outline"
          size="small"
        ></el-button>
      </el-tooltip>
    </div>
  </div>
</template>

<script>
import enrollmentApi from "@/apis/enrollment";
import { EnumEnrollmentStatuses } from "@/enums";

export default {
  components: {},
  props: {
    enrollmentId: {
      type: String,
      default: null
    },
    isEdit: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    async markUnread() {
      await enrollmentApi.markUnread(this.enrollmentId);
      this.$router.push({ name: "EnrollmentList" });
    },
    async deleteEnrollment() {
      await enrollmentApi.setStatus({
        id: this.enrollmentId,
        status: EnumEnrollmentStatuses.deleted
      });
      this.$router.push({ name: "EnrollmentList" });
    }
  }
};
</script>

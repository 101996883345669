<template>
  <div v-if="parent_profile.basic_info.id !== undefined">
    <el-row>
      <el-button
        v-if="editable"
        plain
        type="success"
        size="small"
        @click="editProfile(parent_profile.basic_info.id)"
      >
        編輯家長資料
      </el-button>
      <el-button
        v-if="editable"
        plain
        type="success"
        size="small"
        @click="openChangeExistParentForm = true"
      >
        異動當前報名表的家長
      </el-button>
    </el-row>
    <el-form
      v-if="openChangeExistParentForm"
      @submit.prevent.native="addParent"
      :model="parentForm"
      :validate-on-rule-change="false"
      ref="changeExistParentForm"
      label-position="right"
      label-width="150px"
    >
      <el-form-item
        required
        prop="parent_user_id"
        :rules="commonRules.input"
        :label="$t('enrollment.step2.chooseExistParent')"
      >
        <el-select filterable v-model="parentForm.parent_user_id">
          <el-option
            :key="parent.id"
            v-for="parent in parents"
            :value="parent.id"
            :label="`${parent.name} (${parent.email}, ${parent.phone})`"
          />
        </el-select>
      </el-form-item>
      <!-- 儲存 -->
      <el-form-item class="text-right">
        <el-button @click="openChangeExistParentForm = false">
          {{ $t("button.cancel") }}
        </el-button>
        <el-button native-type="submit" type="primary">
          {{ $t("enrollment.detail.saveButton") }}
        </el-button>
      </el-form-item>
    </el-form>
    <template v-else>
      <!-- 家長姓名 -->
      <el-row>
        <el-col :span="2" class="label">{{
          $t("enrollment.step2.parent.name.label")
        }}</el-col>
        <el-col :span="22">
          {{
            userMathods.displayName(
              parent_profile.basic_info.first_name,
              parent_profile.basic_info.last_name,
              parent_profile.basic_info.chinese_name
            )
          }}
        </el-col>
      </el-row>
      <!-- 家長 email -->
      <el-row>
        <el-col :span="2" class="label">{{
          $t("enrollment.step2.parent.email.label")
        }}</el-col>
        <el-col :span="22">
          {{ parent_profile.basic_info.email }}
        </el-col>
      </el-row>
      <!-- 家長電話 -->
      <el-row>
        <el-col :span="2" class="label">{{
          $t("enrollment.step2.parent.phone.label")
        }}</el-col>
        <el-col :span="22">
          {{ parent_profile.basic_info.phone }}
        </el-col>
      </el-row>
      <!-- 家長家裡電話 -->
      <el-row>
        <el-col :span="2" class="label">{{
          $t("enrollment.step2.parent.home.label")
        }}</el-col>
        <el-col :span="22">
          {{ parent_profile.basic_info.home_phone }}
        </el-col>
      </el-row>
    </template>
  </div>
  <div v-else>
    <div v-if="editable">
      <!-- ===== 選擇現有家長 ===== -->
      <el-form
        v-if="!openNewParentForm"
        @submit.prevent.native="addParent"
        :model="parentForm"
        :validate-on-rule-change="false"
        ref="parentUserIdForm"
        label-position="right"
        label-width="150px"
      >
        <el-form-item
          required
          prop="parent_user_id"
          :rules="commonRules.input"
          :label="$t('enrollment.step2.chooseExistParent')"
        >
          <el-select
            class="parentSelector"
            filterable
            v-model="parentForm.parent_user_id"
          >
            <el-option
              :value="ADD_NEW_PARENT"
              label="找不到家長嗎？增加一個新家長！"
            />
            <el-option
              :key="parent.id"
              v-for="parent in parents"
              :value="parent.id"
              :label="`${parent.name} (${parent.email}, ${parent.phone})`"
            />
          </el-select>
        </el-form-item>
        <!-- 儲存 -->
        <el-form-item class="text-right">
          <el-button native-type="submit" type="primary">
            {{ $t("enrollment.detail.saveButton") }}
          </el-button>
        </el-form-item>
      </el-form>
      <!-- ===== 新增家長姓名 ===== -->
      <el-form
        v-if="openNewParentForm"
        @submit.prevent.native="addParent"
        :model="parentForm"
        :validate-on-rule-change="false"
        ref="parentForm"
        label-position="right"
        label-width="150px"
      >
        <el-button
          plain
          type="success"
          size="small"
          @click="toggleNewParentFormVisible(false)"
        >
          選擇現有家長
        </el-button>
        <!-- ===== 家長姓名 ===== -->
        <el-form-item
          :label="$t('enrollment.step2.parent.name.label')"
          :rules="commonRules.input"
          prop="first_name"
          required
        >
          <div class="row">
            <div class="col-12 col-sm-6 col-md-4 pt-0">
              <el-form-item
                :rules="commonRules.input"
                prop="first_name"
                required
              >
                <el-input
                  v-model="parentForm.first_name"
                  :placeholder="
                    $t('enrollment.step2.parent.name.placeholder.first')
                  "
                  required
                ></el-input>
              </el-form-item>
            </div>
            <div class="col-12 col-sm-6 col-md-4 pt-0">
              <el-form-item
                :rules="commonRules.input"
                prop="last_name"
                required
              >
                <el-input
                  v-model="parentForm.last_name"
                  :placeholder="
                    $t('enrollment.step2.parent.name.placeholder.last')
                  "
                  required
                ></el-input>
              </el-form-item>
            </div>
            <div class="col-12 col-sm-6 col-md-4 pt-0">
              <el-form-item>
                <el-input
                  v-model="parentForm.chinese_name"
                  :placeholder="
                    $t('enrollment.step2.parent.name.placeholder.other')
                  "
                ></el-input>
              </el-form-item>
            </div>
          </div>
        </el-form-item>

        <!-- ===== 家長 email ===== -->
        <el-form-item
          :label="$t('enrollment.step2.parent.email.label')"
          :rules="[commonRules.input, commonRules.email]"
          prop="email"
          required
        >
          <el-input
            v-model="parentForm.email"
            type="email"
            :placeholder="$t('enrollment.step2.parent.email.placeholder')"
            required
          ></el-input>
        </el-form-item>

        <!-- ===== 家長電話 ===== -->
        <el-form-item
          :label="$t('enrollment.step2.parent.phone.label')"
          :rules="commonRules.input"
          prop="phone"
          required
        >
          <el-input
            v-model="parentForm.phone"
            type="tel"
            :placeholder="$t('enrollment.step2.parent.phone.placeholder')"
            required
          ></el-input>
        </el-form-item>

        <!-- ===== 家長家裡電話 ===== -->
        <el-form-item :label="$t('enrollment.step2.parent.home.label')">
          <el-input
            v-model="parentForm.home_phone"
            type="tel"
            :placeholder="$t('enrollment.step2.parent.home.placeholder')"
          ></el-input>
        </el-form-item>

        <!-- 儲存 -->
        <el-form-item class="text-right">
          <el-button native-type="submit" type="primary">
            {{ $t("enrollment.detail.saveButton") }}
          </el-button>
        </el-form-item>
      </el-form>
    </div>
    <div v-else>
      <p>暫無家長資料。</p>
    </div>
  </div>
</template>

<script>
import { user } from "@ivy-way/material";
import formMixin from "@/mixins/form";
import profileTest from "@/mixins/profileTest";

import enrollmentApi from "@/apis/enrollment";

export default {
  props: {
    enrollmentId: {
      type: String,
      default: null
    },
    editable: {
      type: Boolean,
      default: false
    },
    parent_profile: {
      type: Object,
      default: () => ({})
    }
  },
  mixins: [formMixin, profileTest],
  data() {
    return {
      parentForm: {
        parent_user_id: null,
        first_name: "",
        last_name: "",
        chinese_name: "",
        email: "",
        phone: "",
        home_phone: ""
      },
      openNewParentForm: false,
      openChangeExistParentForm: false
    };
  },
  watch: {
    "parentForm.parent_user_id"() {
      if (this.parentForm.parent_user_id === this.ADD_NEW_PARENT) {
        this.toggleNewParentFormVisible(true);
      }
    }
  },
  computed: {
    ADD_NEW_PARENT() {
      return "ADD_NEW_PARENT";
    },
    parents() {
      return this.$store.getters["profile/getParents"];
    },
    userMathods() {
      return user;
    }
  },
  async created() {
    this.$store.dispatch("profile/fetchParents");
  },
  methods: {
    async addParent() {
      let valid = null;
      let form = { enrolled_session_id: this.enrollmentId };

      if (this.openNewParentForm) {
        this.openNewParentForm = false;
        valid = await this.validateForm(this.$refs["parentForm"]);
        form = {
          ...form,
          parent: JSON.parse(JSON.stringify(this.parentForm))
        };
      } else if (this.openChangeExistParentForm) {
        this.openChangeExistParentForm = false;
        valid = await this.validateForm(this.$refs["changeExistParentForm"]);
        form = {
          ...form,
          parent_user_id: this.parentForm.parent_user_id
        };
      } else {
        valid = await this.validateForm(this.$refs["parentUserIdForm"]);
        form = {
          ...form,
          parent_user_id: this.parentForm.parent_user_id
        };
      }

      if (!valid) return;

      try {
        await enrollmentApi.addParent(form);
        this.$emit("refreshEnrollment");
        this.$message.success(this.$t("message.create_success"));
      } catch (e) {
        this.$message.error(this.$t("message.something_went_wrong"));
      }
    },
    editProfile(uid) {
      let routeUrl = this.$router.resolve({
        name: "ProfileForAdmin",
        params: { id: uid }
      });
      window.open(routeUrl.href, "_blank");
    },
    toggleNewParentFormVisible(open) {
      this.openNewParentForm = open;
      this.parentForm = {
        ...this.parentForm,
        parent_user_id: null
      };
    }
  }
};
</script>

<style lang="scss" scoped>
.parentSelector {
  width: 100%;
}
</style>

<template>
  <div class="steps">
    <el-steps :align-center="true">
      <el-step icon="el-icon-s-order">
        <template slot="icon">
          <span class="active">
            <i class="el-icon-s-order" />
            <span>{{ $t("enrollment.list.title") }}</span>
          </span>
        </template>
        <template slot="title">
          <slot name="enrollStep" />
        </template>
      </el-step>
      <el-step icon="el-icon-s-order">
        <template slot="icon">
          <span :class="hasLog ? 'active' : ''">
            <i class="el-icon-edit" />
            <span>{{ $t("enrollment.detail.log") }}</span>
          </span>
        </template>
        <template slot="title">
          <span @click="$emit('toggleLogDialog', true)">
            <i class="el-icon-chat-line-square action-icon" />
          </span>
        </template>
      </el-step>
      <el-step icon="el-icon-s-order">
        <template slot="icon">
          <span :class="hasContract ? 'active' : ''">
            <i class="el-icon-edit" />
            <span>{{ $t("enrollment.detail.contract") }}</span>
          </span>
        </template>
        <template slot="title">
          <ContractStatus
            :contractCreatedAt="enrollment.contract_created_at"
            :contractSignedAt="enrollment.contract_signed_at"
            :hasContract="hasContract"
            :hasSignedContract="hasSignedContract"
            @openContractDialog="
              () => {
                $emit('toggleContractDialog', true);
              }
            "
          />
        </template>
      </el-step>
      <el-step v-if="needToPayFirst || isEdit" icon="el-icon-s-order">
        <template slot="icon">
          <span :class="isPaid ? 'active' : ''">
            <i class="el-icon-money" />
            <span>{{ $t("enrollment.list.paymentStatus") }}</span>
            <span
              >({{
                enrollment.need_pay_first
                  ? $t("enrollment.detail.payList[1]")
                  : $t("enrollment.detail.payList[0]")
              }})</span
            >
          </span>
          <div v-if="isEdit">
            (
            <el-switch
              @change="$emit('setPayFirstOrLater')"
              v-model="enrollment.need_pay_first"
              :inactive-text="$t('enrollment.detail.payList[0]')"
              :inactive-value="0"
              :active-text="$t('enrollment.detail.payList[1]')"
              :active-value="1"
            />
            )
          </div>
        </template>
        <template slot="title">
          <el-tooltip
            v-if="isPaid && !enrollment.order"
            content="此為舊資料轉入，無對應帳單"
            placement="top"
            popper-class="tooltipColor"
          >
            <router-link
              :to="{
                name: 'billView',
                params: { billId: enrollment.order.id }
              }"
            >
              <i class="fas fa-check action-icon" />
            </router-link>
          </el-tooltip>
          <div v-if="parent">
            <router-link
              :to="{
                name: 'Balance',
                query: { username: parent.basic_info.username }
              }"
            >
              {{
                addCurrencySymbol(
                  parent.basic_info.account_balance,
                  parent.basic_info.account_balance_currency
                )
              }}
            </router-link>
            <router-link
              :to="{
                name: 'FinanceAdd',
                query: {
                  parent: parent.basic_info.username
                }
              }"
            >
              （+ 儲值）
            </router-link>
          </div>
          <span v-else>
            此學生無對應家長
          </span>
        </template>
      </el-step>
      <el-step icon="el-icon-s-order">
        <template slot="icon">
          <span :class="hasPlacementTest.user_exam_id ? 'active' : ''">
            <i class="el-icon-tickets" />
            <span>{{ $t("enrollment.detail.placementTest") }}</span>
          </span>
        </template>
        <template slot="title">
          <ExamStatus
            :enrollment="enrollment"
            :hasPlacementTest="hasPlacementTest"
            @toggleAssignTestDialog="$emit('toggleAssignTestDialog', true)"
          />
        </template>
      </el-step>
      <el-step icon="el-icon-s-order">
        <template slot="icon">
          <span :class="isFinisheClassPlacement ? 'active' : ''">
            <i class="el-icon-folder" />
            <span>{{ $t("enrollment.detail.private") }}</span>
          </span>
        </template>
        <template slot="title">
          <el-tooltip
            v-if="!isExistPrivateLesson"
            :content="$t('enrollment.steps.message.createPrivateLesson')"
            placement="top"
            popper-class="tooltipColor"
          >
            <router-link
              :to="{
                name: 'privateCreate',
                params: {
                  privateType
                },
                query: {
                  enrolled_session_id: enrollment.id
                }
              }"
            >
              <i class="el-icon-plus" />
            </router-link>
          </el-tooltip>
          <div v-if="isExistPrivateLesson">
            <router-link
              :to="{
                path: isExistTeacher
                  ? `/private/${privateType}/${enrollment.student_class_tickets[0].class_id}/detail`
                  : `/private/${privateType}/link-teacher/${enrollment.student_class_tickets[0].class_id}`
              }"
            >
              {{ lessonTitle }}
            </router-link>
          </div>
        </template>
      </el-step>
    </el-steps>
  </div>
</template>

<script>
import ExamStatus from "@/views/home/enrollment/steps/ExamStatus";
import ContractStatus from "@/views/home/enrollment/steps/ContractStatus";
import moneyMixin from "@/mixins/money";
import { EnumPrivateSessions } from "@/enums";

export default {
  mixins: [moneyMixin],
  components: { ExamStatus, ContractStatus },
  props: [
    "enrollment",
    "hasLog",
    "isPaid",
    "hasContract",
    "hasSignedContract",
    "hasPlacementTest",
    "isExistPrivateLesson",
    "isFinisheClassPlacement",
    "parent",
    "isEdit"
  ],
  computed: {
    isCounseling() {
      return EnumPrivateSessions.counselingIds.includes(
        this.enrollment.course_session_id
      );
    },
    privateType() {
      // return this.enrollment.session_classes[0]?.subject_type === "Tutoring" ? "private-lessons" ;
      return this.isCounseling ? "counseling" : "private-lessons";
    },
    lessonTitle() {
      return this.enrollment.student_class_tickets[0].session_class?.title;
    },
    isExistTeacher() {
      return this.enrollment.session_classes[0]?.look_for_teacher;
    },
    needToPayFirst() {
      return this.enrollment.need_pay_first === 1;
    }
  }
};
</script>

<style scoped>
.active {
  color: #42a16a;
}

::v-deep .is-icon {
  width: 240px;
}

@media only screen and (min-width: 992px) {
  .steps-buttons >>> .el-col-md-4-8 {
    width: 20%;
  }
}

.steps {
  margin-bottom: 15px;
}
</style>

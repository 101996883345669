<template>
  <div class="ivy-enrollment-form container-fluid">
    <Breadcrumb>
      <template slot="lastPage">
        {{ this.course_session.title }}
      </template>
    </Breadcrumb>
    <ClassesTabs
      v-if="viewEnrolledSessions && viewEnrolledSessions.length > 0"
      :user="pageTitle"
      :enrollmentId="id"
      :filterStatus="filterStatus"
      :showEnrolledSessions="viewEnrolledSessions"
      :studentEnrolledSessions="
        enrolled_session.student_enrolled_sessions || []
      "
      @statusChangeButton="statusChangeButton"
      @routeToEnrollmentSession="routeToEnrollmentSession"
    />
    <hr />
    <el-alert
      v-if="isCancelled"
      show-icon
      :closable="false"
      title="此報名表已被取消"
      type="warning"
    />
    <el-alert
      v-if="
        (!isPrivateLesson() || enrolled_session.need_pay_first) &&
          !isPaid &&
          isFinisheClassPlacement
      "
      title="此報名表未完成付款，卻已完成分班！請儘速處理！"
      type="error"
      show-icon
    >
    </el-alert>
    <ControlButtons
      :isEdit="canEdit"
      :enrollmentId="id"
      @changeCourse="changeCourse"
      @changeEdit="changeEdit"
      @colseEditMode="canEdit = false"
    />
    <br />
    <h2>
      <div style="white-space: nowrap; display: inline-block">
        <!-- 明確的梯次 -->
        <span v-if="this.course_session.title">
          {{ this.course_session.title }}
        </span>
        <!-- 想上的課 -->
        <div
          v-else
          :key="course.id"
          v-for="(course, index) in enrolled_session.interested_courses"
          style="display: inline"
        >
          {{ course }}
          <span
            :key="`br-${index}`"
            v-if="
              enrolled_session.interested_courses != null &&
                index < enrolled_session.interested_courses.length - 1
            "
            >,</span
          >
        </div>
        <!-- 家教 -->
        <span v-if="enrolled_session.custom_online">
          {{ `-${enrolled_session.custom_online}` }}
        </span>
        <span v-if="enrolled_session.custom_offline">
          {{ `-${enrolled_session.custom_offline}` }}
        </span>
      </div>
    </h2>
    <UserTags :tags="this.student_profile.basic_info.tags" />
    <hr />
    <!-- ===== 填表時間 ===== -->
    <p>
      {{ $t("enrollment.list.date") }}：
      {{ instant.formatDateTime(enrolled_session.created_at) }}
    </p>
    <PrivateClassSteps
      v-if="isPrivateLesson()"
      :isEdit="canEdit"
      :isExistPrivateLesson="isExistPrivateLesson()"
      :isFinisheClassPlacement="isFinisheClassPlacement"
      :enrollment="enrolled_session"
      :hasLog="hasLog"
      :isPaid="isPaid"
      :hasPlacementTest="hasPlacementTest"
      :parent="parent_profile"
      :hasContract="hasContract"
      :hasSignedContract="hasSignedContract"
      @toggleContractDialog="toggleContractDialog"
      @toggleLogDialog="toggleLogDialog"
      @toggleAssignTestDialog="toggleAssignTestDialog"
      @setPayFirstOrLater="setPayFirstOrLater"
    >
      <template slot="enrollStep">
        <CurrentStep :currentStep="enrolled_session.current_step" />
      </template>
    </PrivateClassSteps>
    <GroupClassSteps
      v-else
      :isEdit="canEdit"
      :enrollment="enrolled_session"
      :hasLog="hasLog"
      :isPaid="isPaid"
      :hasPlacementTest="hasPlacementTest"
      :isFinisheClassPlacement="isFinisheClassPlacement"
      :hasCourse="hasCourse"
      :hasContract="hasContract"
      :hasSignedContract="hasSignedContract"
      @toggleContractDialog="toggleContractDialog"
      @toggleLogDialog="toggleLogDialog"
      @toggleAssignTestDialog="toggleAssignTestDialog"
      @setPayFirstOrLater="setPayFirstOrLater"
    >
      <template slot="enrollStep">
        <CurrentStep :currentStep="enrolled_session.current_step" />
      </template>
    </GroupClassSteps>
    <el-row justify="space-around" class="text-center steps-buttons">
      <!-- 聯繫紀錄列表 -->
      <el-col :xs="24" :md="24" class="mb-3">
        <el-card shadow="hover">
          <h5>
            {{ $t("enrollment.detail.log") }}
            <el-tooltip
              content="只有管理員可以看到這個紀錄"
              placement="top"
              popper-class="tooltipColor"
            >
              <span><i class="fas fa-lock"/></span>
            </el-tooltip>
            <span class="action-icon" @click="toggleLogDialog(true)">
              <i class="el-icon-chat-line-square" />
            </span>
          </h5>
          <div v-if="logList.length > 0" style="margin-left: 20px">
            <ul class="log" v-for="log in logList" :key="log.id">
              <li>
                {{ log.content }} –
                <a :href="`/user/${log.user.id}`"
                  >{{ log.user.first_name }} {{ log.user.last_name }}</a
                >
                <span class="time">
                  ({{ instant.formatGoogleTime(log.created_at) }})</span
                >
                <span v-if="canEdit" @click="deleteContact(log.id)">
                  <i class="fas fa-trash-alt warning-icon" />
                </span>
              </li>
            </ul>
          </div>
          <div v-else>
            <p>暫無聯繫記錄。</p>
          </div>
        </el-card>
      </el-col>

      <!-- 管理員備註 -->
      <el-col :xs="24" :md="24" class="mb-3">
        <el-card shadow="hover">
          <h5>
            {{ $t("enrollment.detail.notes") }}
            <el-tooltip
              content="公開（家長跟學生可以看到這個備註）"
              placement="top"
              popper-class="tooltipColor"
            >
              <span><i class="fas fa-globe-americas"/></span>
            </el-tooltip>
            <span class="action-icon" @click="toggleNotesDialog(true)">
              <i class="el-icon-tickets" />
            </span>
          </h5>
          <div
            v-if="
              enrollmentForm.admin_notes !== '' &&
                enrollmentForm.admin_notes !== null
            "
          >
            <p>{{ enrollmentForm.admin_notes }}</p>
          </div>
          <div v-else>
            <p>暫無備註。</p>
          </div>
        </el-card>
      </el-col>
    </el-row>
    <LogDialog
      :id="id"
      :isLogDialogVisible="isLogDialogVisible"
      @reload="getLogs"
      @toggleLogDialog="toggleLogDialog"
    />
    <PlacementTestDialog
      :id="id"
      :enrolled_session="enrolled_session"
      :isAssignTestDialogVisible="isAssignTestDialogVisible"
      @toggleAssignTestDialog="toggleAssignTestDialog"
      @reload="init"
    />
    <ContractDialog
      :id="id"
      :enrolled_session="enrolled_session"
      :isContractDialogVisible="isContractDialogVisible"
      :hasContract="hasContract"
      :hasSignedContract="hasSignedContract"
      @toggleContractDialog="toggleContractDialog"
      @reload="init"
    />

    <!-- 管理員備註 Dialog -->
    <el-dialog :visible.sync="isNotesDialogVisible" center>
      <h5 slot="title">
        {{ $t("enrollment.detail.notes") }}
        <el-tooltip
          content="公開（家長跟學生可以看到這個備註）"
          placement="top"
          popper-class="tooltipColor"
        >
          <span><i class="fas fa-globe-americas"/></span>
        </el-tooltip>
      </h5>
      <el-form @submit.prevent.native="updateAdminNotes()">
        <el-form-item required>
          <el-input
            type="textarea"
            v-model="enrollmentForm.admin_notes"
            :rows="5"
          ></el-input>
        </el-form-item>
        <el-form-item class="text-right">
          <el-button native-type="submit" type="primary">
            {{ $t("enrollment.detail.saveButton") }}
          </el-button>
        </el-form-item>
      </el-form>
    </el-dialog>

    <el-tabs id="tabs" v-model="activeTag" type="border-card" class="mb-3">
      <!-- ===== 學生資料 ===== -->
      <el-tab-pane :label="tabList[0]" :key="0" name="student">
        <StudentForm
          :enrollmentId="id"
          :editable="canEdit"
          :student_profile="student_profile"
          @refreshEnrollment="load"
        />
      </el-tab-pane>

      <!-- ===== 家長資料 ===== -->
      <el-tab-pane :label="tabList[1]" :key="1" name="parent">
        <ParentForm
          :enrollmentId="id"
          :editable="canEdit"
          :parent_profile="parent_profile"
          @refreshEnrollment="load"
        />
      </el-tab-pane>

      <!-- ===== 填表人資料 ===== -->
      <el-tab-pane :label="tabList[2]" :key="2" name="person">
        <el-row>
          <!-- 填表人與學生的關係 -->
          <el-col :span="3" class="label">{{
            $t("enrollment.list.role")
          }}</el-col>
          <el-col :span="21">
            {{ enrolled_session.role }}
          </el-col>
        </el-row>
        <el-row>
          <!-- 填表人名字 -->
          <el-col :span="3" class="label">{{
            $t("enrollment.step1.firstName")
          }}</el-col>
          <el-col :span="21">
            {{ enrolled_session.first_name }}
          </el-col>
        </el-row>
        <el-row>
          <!-- 填表人 email -->
          <el-col :span="3" class="label">{{
            $t("enrollment.step1.email")
          }}</el-col>
          <el-col :span="21">
            {{ enrolled_session.email }}
          </el-col>
        </el-row>
        <el-row>
          <!-- 填表人電話 -->
          <el-col :span="3" class="label">{{
            $t("enrollment.step1.phone")
          }}</el-col>
          <el-col :span="21">
            {{ enrolled_session.phone }}
          </el-col>
        </el-row>
        <el-row>
          <!-- 學生想上的課 -->
          <el-col :span="3" class="label">{{
            $t("enrollment.step1.classesToEnroll")
          }}</el-col>
          <el-col :span="21">
            <ul>
              <li
                v-for="(course, index) in enrolled_session.interested_courses"
                :key="index"
              >
                {{ course }}
              </li>
            </ul>
          </el-col>
        </el-row>
      </el-tab-pane>

      <!-- ===== 報名表資料 ===== -->
      <el-tab-pane :label="tabList[3]" :key="3" name="course">
        <EnrollmentForm
          :enrollmentId="id"
          :editable="canEdit"
          :enrollmentForm="enrollmentForm"
          :enrolled_session="enrolled_session"
          :isPrivateLesson="isPrivateLesson()"
          :isExistPrivateLesson="isExistPrivateLesson()"
          @setPayFirstOrLater="setPayFirstOrLater"
          @updateEnrollment="update"
          @setEnrollment="
            enrollment => {
              enrollmentForm = {
                ...enrollmentForm,
                ...enrollment
              };
            }
          "
        />
      </el-tab-pane>
    </el-tabs>
    <template v-if="student_profile.student && !hasSurvey">
      <el-alert
        class="mt-4 mb-4"
        title="該學生沒有填寫問卷調查"
        type="info"
        :closable="false"
        show-icon
        effect="dark"
      >
        <router-link
          :to="{
            name: 'Survey',
            query: { student: student_profile.basic_info.id }
          }"
          class="survey-link"
        >
          管理員可以幫助填寫問卷調查
        </router-link>
      </el-alert>
    </template>
    <hr />
    <template v-if="student_profile.student && hasSurvey">
      <el-card class="mb-4">
        <Heading :content="$t('survey.Background')" heading="h4">
          <router-link
            slot="button"
            :to="{
              name: 'Survey',
              query: { student: student_profile.basic_info.id }
            }"
            class="survey-link"
          >
            <el-button type="primary" size="mini">
              <i class="fa fa-edit"></i>
            </el-button>
          </router-link>
        </Heading>
        <hr />
        <div class="survey-questions" v-if="student_profile.student">
          <div v-for="(question, index) in questions" :key="index">
            <div class="d-flex">
              <div>
                <h2>
                  <b>
                    {{ question.question }}
                  </b>
                </h2>
                <!-- <p>{{ question.description }}</p> -->
                <div v-html="student_profile.student[question.prop]"></div>
              </div>
            </div>
            <hr />
          </div>
        </div>
      </el-card>
    </template>
  </div>
</template>

<script>
import _ from "lodash";
import { instant, user } from "@ivy-way/material";
import testList from "@/data/tests.json";
import Breadcrumb from "@/components/Breadcrumb";
import LogDialog from "@/components/enrollment/LogDialog";
import PlacementTestDialog from "@/components/enrollment/PlacementTestDialog";
import ContractDialog from "@/components/enrollment/ContractDialog";
import CurrentStep from "@/components/enrollment/CurrentStep";
import UserTags from "@/components/tags/UserTags";

import ClassesTabs from "@/views/home/enrollment/components/ClassesTabs";
import ControlButtons from "@/views/home/enrollment/components/ControlButtons";
import GroupClassSteps from "@/views/home/enrollment/steps/GroupClassSteps";
import PrivateClassSteps from "@/views/home/enrollment/steps/PrivateClassSteps";
import StudentForm from "@/views/home/enrollment/components/StudentForm";
import ParentForm from "@/views/home/enrollment/components/ParentForm";
import EnrollmentForm from "@/views/home/enrollment/components/EnrollmentForm";

import formMixin from "@/mixins/form";
import profileTest from "@/mixins/profileTest";

import enrollmentApi from "@/apis/enrollment";
import { EnumEnrollmentStatuses, EnumPrivateSessions } from "@/enums";

export default {
  metaInfo() {
    return {
      title: `${this.pageTitle} - Ivy-Way Academy`
    };
  },
  components: {
    Breadcrumb,
    LogDialog,
    PlacementTestDialog,
    ContractDialog,
    GroupClassSteps,
    PrivateClassSteps,
    CurrentStep,
    UserTags,
    ClassesTabs,
    ControlButtons,
    StudentForm,
    ParentForm,
    EnrollmentForm
  },

  mixins: [formMixin, profileTest],

  props: ["id"],
  data() {
    return {
      questions: [
        {
          question: this.$t("survey.Q1"),
          description: this.$t("survey.Q1-text"),
          prop: "ideal_college_major",
          answer: ""
        },
        {
          question: this.$t("survey.Q2"),
          description: this.$t("survey.Q2-text"),
          prop: "dream_schools",
          answer: ""
        },
        {
          question: this.$t("survey.Q3"),
          description: this.$t("survey.Q3-text"),
          prop: "current_situation",
          answer: ""
        },
        {
          question: this.$t("survey.Q4"),
          description: this.$t("survey.Q4-text"),
          prop: "how_can_we_help",
          answer: ""
        },
        {
          question: this.$t("survey.Q5"),
          description: this.$t("survey.Q5-text"),
          prop: "ideal_counselor",
          answer: ""
        },
        {
          question: this.$t("survey.Q6"),
          description: this.$t("survey.Q6-text"),
          prop: "availability_to_meet",
          answer: ""
        }
      ],
      lodash: _,
      filterStatus: EnumEnrollmentStatuses.read,
      canEdit: false,
      isLogDialogVisible: false,
      isAssignTestDialogVisible: false,
      isContractDialogVisible: false,
      isNotesDialogVisible: false,

      logList: [],

      student_profile: {
        basic_info: {
          first_name: "",
          last_name: ""
        },
        student: {}
      },
      parent_profile: {
        basic_info: {
          first_name: "",
          last_name: ""
        }
      },
      enrolled_session: {
        interested_courses: []
      },
      course_session: {},

      enrollmentForm: {
        // 報名表資料
        course_session_id: null,
        had_similar_course: null,
        past_course_info: "",
        past_course_exp: "",
        enroll_with_others: null,
        other_students: "",
        other_info: "",
        admin_notes: "",
        entry_test_paper_id: 0,
        // 學生資料
        know_us: "",
        will_mandarin_help: null,
        is_student_referral: null,
        referrer: "",
        payment_method: null,
        currency: null,
        need_pay_first: 0
      },
      processStep: 1,
      activeTag: "student"
    };
  },
  computed: {
    hasSurvey() {
      let hasSurvey = false;
      if (
        this.student_profile &&
        this.student_profile.student &&
        (this.student_profile.student.ideal_college_major ||
          this.student_profile.student.dream_schools ||
          this.student_profile.student.current_situation ||
          this.student_profile.student.how_can_we_help ||
          this.student_profile.student.ideal_counselor ||
          this.student_profile.student.availability_to_meet)
      ) {
        hasSurvey = true;
      }
      return hasSurvey;
    },
    instant() {
      return instant;
    },
    userMethods() {
      return user;
    },
    pageTitle() {
      let name = `${this.enrolled_session.first_name}（聯絡人）`;
      if (this.student_profile.basic_info.first_name) {
        name = this.student_profile.basic_info.first_name;
      }
      return `${name} 的報名表`;
    },
    view() {
      return this.$route.query.view;
    },
    viewEnrolledSessions() {
      // if (this.filterStatus === 0) {
      //   return (this.enrolled_session?.student_enrolled_sessions || []).filter(({ is_read }) => (
      //     !is_read
      //   ));
      // } else {
      //   return (this.enrolled_session?.student_enrolled_sessions || []).filter(({ status }) => (
      //     status === this.filterStatus
      //   ));
      // }
      let enrolled_sessions = _.orderBy(
        this.enrolled_session?.student_enrolled_sessions || [],
        "created_at",
        "desc"
      );
      return enrolled_sessions;
    },
    hasCourse() {
      return JSON.stringify(this.course_session) !== "{}";
    },
    hasLog() {
      return this.logList.length > 0;
    },
    hasContract() {
      return this.enrolled_session.contract_url !== null;
    },
    hasSignedContract() {
      return this.enrolled_session.signed_contract_url !== null;
    },
    isCancelled() {
      return (
        this.enrolled_session.status === this.EnumEnrollmentStatuses.canceled
      );
    },
    isPaid() {
      return this.enrolled_session.payment_status === 1;
    },
    hasPlacementTest() {
      let val = {
        exam_id: this.enrolled_session.entry_test_paper_id,
        user_exam_id: this.enrolled_session.entry_test_result_id,
        user_exam: this.enrolled_session.userExam,
        entry_test_type: this.enrolled_session.entry_test_type,
        entry_test_date: this.enrolled_session.entry_test_date,
        exam: this.enrolled_session.exam
          ? this.enrolled_session.exam
          : this.enrolled_session.userExam
            ? this.enrolled_session.userExam.exam
            : null,
        score: this.enrolled_session.score,
        student_id: this.enrolled_session.student_user_id
      };
      return val;
    },
    // hasPlacementTest() {
    //   return this.enrolled_session.entry_test_result_id;
    // },
    isFinisheClassPlacement() {
      return this.enrolled_session.arrange_status;
    },
    statusList() {
      return this.$t("enrollment.list.statusList");
    },
    // 以下讀 i18n 的資料
    tabList() {
      return this.$t("enrollment.detail.tabList");
    },
    tutorList() {
      return this.$t("enrollment.step3.tutorList");
    },
    EnumEnrollmentStatuses() {
      return EnumEnrollmentStatuses;
    },
    needPayFirst: {
      get() {
        return this.enrolled_session.need_pay_first === 1;
      },
      set(value) {
        this.enrolled_session.need_pay_first = value ? 1 : 0;
      }
    }
  },
  watch: {
    $route() {
      this.init();
    }
  },

  async mounted() {
    await this.init();
    if (this.enrolled_session.is_read === 0) {
      this.statusChangeButton(this.EnumEnrollmentStatuses.unread);
    } else {
      this.statusChangeButton(this.enrolled_session.status);
    }
    this.defaultOpenDialog();
    if(this.view === "contract") {
      this.isContractDialogVisible = true;
    }
  },

  methods: {
    statusChangeButton(status) {
      this.filterStatus = status;
    },
    defaultOpenDialog() {
      switch (this.$route.query.openDiaLog) {
        case "assignTest":
          this.toggleAssignTestDialog(true);
          break;
        case "log":
          this.toggleLogDialog(true);
          break;
      }
    },
    async init() {
      const [, , paperRes] = await Promise.all([
        this.load(),
        this.getLogs(),
        enrollmentApi.fetchTestPapers()
      ]);

      // 分班考試卷

      if (this.enrolled_session.is_read) {
        return;
      }

      // 報名表設為已讀
      enrollmentApi.read({
        id: this.id,
        is_read: 1
      });
    },
    async load() {
      const enrollmentRes = await enrollmentApi.fetch({ id: this.id });
      // 報名表明細
      const {
        student_profile,
        parent_profile,
        enrolled_session,
        course_session
      } = enrollmentRes;

      if (student_profile) {
        // 整理考試成績
        student_profile.testScores = this.generateTestScores(
          student_profile.student
        );

        // 將學生資料複製到報名表
        const {
          know_us,
          is_student_referral,
          referrer,
          will_mandarin_help,
          payment_method,
          currency
        } = student_profile.student;

        const studentProfile = {
          know_us,
          is_student_referral,
          referrer,
          will_mandarin_help,
          payment_method,
          currency
        };

        Object.assign(this.enrollmentForm, studentProfile);
      }

      if (enrolled_session) {
        const {
          course_session_id,
          had_similar_course,
          past_course_info,
          past_course_exp,
          enroll_with_others,
          other_students,
          other_info,
          admin_notes,
          entry_test_paper_id
        } = enrolled_session;

        const enrolledSession = {
          course_session_id: course_session_id || null,
          had_similar_course,
          past_course_info,
          past_course_exp,
          enroll_with_others,
          other_students,
          other_info,
          admin_notes,
          entry_test_paper_id
        };

        Object.assign(this.enrollmentForm, enrolledSession);
      }
      console.log(enrolled_session);

      this.student_profile = student_profile || this.student_profile;
      this.parent_profile = parent_profile || this.parent_profile;
      this.enrolled_session = enrolled_session || {};
      this.course_session = course_session || {};
    },
    async getLogs() {
      const logRes = await enrollmentApi.fetchContacts({ id: this.id });

      const { contacts } = logRes;

      contacts.forEach(contact => {
        const name =
          this.userMethods.displayName(
            contact.user.first_name,
            contact.user.last_name
          ) || "**No One**";
        const datetime = this.instant.formatDateTime(contact.created_at);

        contact.label = `${name}（${datetime}）`;
      });

      this.logList = contacts;
    },
    toggleLogDialog(visible) {
      this.isLogDialogVisible = visible;
    },
    toggleAssignTestDialog(visible) {
      this.isAssignTestDialogVisible = visible;
    },
    toggleNotesDialog(visible) {
      this.isNotesDialogVisible = visible;
    },
    toggleContractDialog(visible) {
      this.isContractDialogVisible = visible;
    },
    isExistPrivateLesson() {
      try {
        return Boolean(this.enrolled_session.student_class_tickets[0].class_id);
      } catch (e) {
        return false;
      }
    },
    isPrivateLesson() {
      const privateCourseId = [
        ...EnumPrivateSessions.privateLessonIds,
        ...EnumPrivateSessions.counselingIds
      ];
      return privateCourseId.includes(this.enrolled_session.course_session_id);
    },
    generateTestScores(student) {
      const testScores = {};

      testList.forEach(test => {
        const testName = test.name;

        const studentTest = student[testName];

        if (!studentTest || !Object.keys(studentTest).length) {
          return;
        }

        const testScore = new TestScore();

        testScore.is_real_test = studentTest.is_real_test;
        testScore.taken_on = studentTest.taken_on;

        Object.keys(studentTest).forEach(column => {
          if (column.indexOf("_score") === -1) {
            return;
          }

          const item = column.replace("_score", "");

          testScore.scores[item] = studentTest[column];
        });

        testScores[testName] = testScore;
      });

      return testScores;
    },

    async deleteContact(id) {
      await enrollmentApi.deleteContact({
        id: id
      });
      this.getLogs();
    },

    async update() {
      const form = JSON.parse(JSON.stringify(this.enrollmentForm));
      if (this.student_profile.basic_info.id === undefined) {
        return;
      }
      try {
        await enrollmentApi.update({
          id: this.id,
          ...form,
          entry_test_result_id: this.enrolled_session.entry_test_result_id
        });
        this.load();
        this.canEdit = false;
        this.$message.success(this.$t("message.update_success"));
      } catch (e) {
        this.$message.error(this.$t("message.something_went_wrong"));
      }
    },
    async updateAdminNotes() {
      const form = JSON.parse(JSON.stringify(this.enrollmentForm));

      await enrollmentApi.update({
        id: this.id,
        ...form
      });

      this.toggleAssignTestDialog(false);
      this.toggleNotesDialog(false);

      this.load();
    },
    async updateNeedPayFirst() {
      await enrollmentApi.setPayFirstOrLater({
        id: this.id,
        need_pay_first: this.enrolled_session.need_pay_first
      });

      this.load();
    },
    changeEdit() {
      this.canEdit = true;
      setTimeout(function() {
        window.scroll(0, document.getElementById("tabs").offsetTop - 80);
      }, 100);
    },
    changeCourse() {
      this.canEdit = true;
      this.activeTag = "course";
      setTimeout(function() {
        window.scroll(0, document.getElementById("tabs").offsetTop - 80);
      }, 100);
    },
    routeToEnrollmentSession(sessionId) {
      if (this.canEdit) {
        return this.$confirm(
          "目前正在編輯報名表，如果切換到其他報名表不會儲存當前資料，請問要繼續嗎？",
          this.$t("message.notice"),
          {
            confirmButtonText: this.$t("message.continue"),
            cancelButtonText: this.$t("message.cancel"),
            type: "warning"
          }
        )
          .then(() => {
            this.$router.push({
              name: "EnrollmentDetail",
              params: {
                id: sessionId
              },
              query: {
                filterStatus: this.filterStatus
              }
            });
          })
          .catch(() => {
            throw new Error("Cancel");
          });
      } else {
        this.$router.push({
          name: "EnrollmentDetail",
          params: {
            id: sessionId
          },
          query: {
            filterStatus: this.filterStatus
          }
        });
      }
    },
    setPayFirstOrLater() {
      enrollmentApi.setPayFirstOrLater({
        id: this.id,
        need_pay_first: this.enrolled_session.need_pay_first
      });
    }
  }
};

class TestScore {
  scores = {};
  is_real_test = 0;
  taken_on = "";
}
</script>

<style lang="scss">
@import "@/assets/scss/enrollment.scss";
</style>
<style scoped>
@media only screen and (min-width: 992px) {
  .steps-buttons >>> .el-col-md-4-8 {
    width: 20%;
  }
}

.steps {
  margin-bottom: 15px;
}
.button-style {
  padding: 0 15px;
}
::v-deep .el-form > .el-form-item {
  margin-bottom: 20px;
}

.sessionTabs >>> .el-tabs__item {
  color: #909399;
  background: #f5f7fa;
}

.sessionTabs >>> .is-active {
  color: #42a16a;
  background-color: #ffffff;
  border-right-color: #dcdfe6;
  border-left-color: #dcdfe6;
}

.filterBtns {
  margin: 8px 0px;
}
</style>
<style lang="scss" scoped>
.el-card {
  border-radius: 0;

  &.ivy-card {
    height: 110px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  h5,
  p {
    text-align: left;
  }
}

.el-col {
  margin-bottom: 4px;
}

.label {
  color: #606266;
}

::v-deep .ivy-log {
  position: relative;

  .ivy-delete-button {
    cursor: pointer;
    position: absolute;
    right: 0;
    top: -15px;
  }
}
.el-form-item {
  margin: 0px 0px 0px 0px;
}

.log:first-child {
  border-top: 1px solid #e1e1e1;
}

.log {
  margin-bottom: 0px;
  border-bottom: 1px solid #e1e1e1;
  padding: 4px 4px;
  text-align: left;

  .time {
    color: #666666;
  }
}

.addNewParentIcon {
  font-size: 26px;
  margin: 0px 12px;
}
.survey-link {
  font-weight: bold;
  text-decoration: underline;
  /* margin-left: 0.5rem; */
}
.survey-questions h2 {
  font-size: 18px;
  margin-bottom: 5px;
}
.survey-questions p {
  color: #838383;
  font-size: 14px;
  line-height: 16px;
}
</style>

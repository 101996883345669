var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"steps"},[_c('el-steps',{attrs:{"align-center":true}},[_c('el-step',{attrs:{"icon":"el-icon-s-order"}},[_c('template',{slot:"icon"},[_c('span',{staticClass:"active"},[_c('i',{staticClass:"el-icon-s-order"}),_c('span',[_vm._v(_vm._s(_vm.$t("enrollment.list.title")))])])]),_c('template',{slot:"title"},[_vm._t("enrollStep")],2)],2),_c('el-step',{attrs:{"icon":"el-icon-s-order"}},[_c('template',{slot:"icon"},[_c('span',{class:_vm.hasLog ? 'active' : ''},[_c('i',{staticClass:"el-icon-edit"}),_c('span',[_vm._v(_vm._s(_vm.$t("enrollment.detail.log")))])])]),_c('template',{slot:"title"},[_c('span',{on:{"click":function($event){return _vm.$emit('toggleLogDialog', true)}}},[_c('i',{staticClass:"el-icon-chat-line-square action-icon"})])])],2),_c('el-step',{attrs:{"icon":"el-icon-s-order"}},[_c('template',{slot:"icon"},[_c('span',{class:_vm.hasContract ? 'active' : ''},[_c('i',{staticClass:"el-icon-edit"}),_c('span',[_vm._v(_vm._s(_vm.$t("enrollment.detail.contract")))])])]),_c('template',{slot:"title"},[_c('ContractStatus',{attrs:{"contractCreatedAt":_vm.enrollment.contract_created_at,"contractSignedAt":_vm.enrollment.contract_signed_at,"hasContract":_vm.hasContract,"hasSignedContract":_vm.hasSignedContract},on:{"openContractDialog":() => {
              _vm.$emit('toggleContractDialog', true);
            }}})],1)],2),_c('el-step',{attrs:{"icon":"el-icon-s-order"}},[_c('template',{slot:"icon"},[_c('span',{class:_vm.isPaid ? 'active' : ''},[_c('i',{staticClass:"el-icon-money"}),_c('span',[_vm._v(_vm._s(_vm.$t("enrollment.list.paymentStatus")))]),_c('span',[_vm._v("("+_vm._s(_vm.enrollment.need_pay_first ? _vm.$t("enrollment.detail.payList[1]") : _vm.$t("enrollment.detail.payList[0]"))+")")])]),(_vm.isEdit)?_c('div',[_vm._v(" ( "),_c('el-switch',{attrs:{"inactive-text":_vm.$t('enrollment.detail.payList[0]'),"inactive-value":0,"active-text":_vm.$t('enrollment.detail.payList[1]'),"active-value":1},on:{"change":function($event){return _vm.$emit('setPayFirstOrLater')}},model:{value:(_vm.enrollment.need_pay_first),callback:function ($$v) {_vm.$set(_vm.enrollment, "need_pay_first", $$v)},expression:"enrollment.need_pay_first"}}),_vm._v(" ) ")],1):_vm._e()]),_c('template',{slot:"title"},[(_vm.isPaid)?[(_vm.enrollment.receivable_order)?_c('router-link',{attrs:{"to":{
              name: 'ReceivableView',
              params: { receivableId: _vm.enrollment.receivable_order.id }
            }}},[_c('span',{staticClass:"action-icon"},[_c('i',{staticClass:"fas fa-check"})])]):(_vm.enrollment.order)?_c('el-tooltip',{attrs:{"content":_vm.instant.formatDate(_vm.enrollment.order.mark_paid_at),"placement":"top","popper-class":"tooltipColor"}},[(_vm.enrollment.order)?_c('router-link',{attrs:{"to":{
                name: 'BillView',
                params: { billId: _vm.enrollment.order.id }
              }}},[_c('span',{staticClass:"action-icon"},[_c('i',{staticClass:"fas fa-check"})])]):_vm._e()],1):_c('el-tooltip',{attrs:{"content":"此為舊資料轉入，無對應帳單","placement":"top","popper-class":"tooltipColor"}},[_c('span',{staticClass:"action-icon"},[_c('i',{staticClass:"fas fa-check"})])])]:(!_vm.isPaid && _vm.enrollment.receivable_order)?_c('el-tooltip',{attrs:{"content":_vm.instant.formatDate(_vm.enrollment.receivable_order.created_at),"placement":"top","popper-class":"tooltipColor"}},[_c('router-link',{attrs:{"to":{
              name: 'ReceivableView',
              params: { receivableId: _vm.enrollment.receivable_order.id }
            }}},[_c('span',{staticStyle:{"color":"red"}},[_vm._v("分期付款中")])])],1):(!_vm.isPaid)?_c('router-link',{attrs:{"to":{
            name: 'createBillWithEnrollment',
            params: { id: _vm.enrollment.id }
          }}},[_c('i',{staticClass:"el-icon-plus"})]):_vm._e()],2)],2),_c('el-step',{attrs:{"icon":"el-icon-s-order"}},[_c('template',{slot:"icon"},[_c('span',{class:_vm.hasPlacementTest.user_exam_id ? 'active' : ''},[_c('i',{staticClass:"el-icon-tickets"}),_c('span',[_vm._v(_vm._s(_vm.$t("enrollment.detail.placementTest")))])])]),_c('template',{slot:"title"},[_c('ExamStatus',{attrs:{"enrollment":_vm.enrollment,"hasPlacementTest":_vm.hasPlacementTest},on:{"toggleAssignTestDialog":function($event){return _vm.$emit('toggleAssignTestDialog', true)}}})],1)],2),_c('el-step',{attrs:{"icon":"el-icon-s-order"}},[_c('template',{slot:"icon"},[_c('span',{class:_vm.isFinisheClassPlacement ? 'active' : ''},[_c('i',{staticClass:"el-icon-folder"}),_c('span',[_vm._v(_vm._s(_vm.$t("enrollment.detail.class")))])])]),_c('template',{slot:"title"},[(_vm.hasCourse)?_c('span',_vm._l((_vm.filterNotNull(_vm.enrollment.session_classes)),function(sessionClass){return _c('router-link',{key:sessionClass.id,attrs:{"to":{
              name: 'classDetail',
              params: { classId: sessionClass.id }
            }}},[_vm._v(" "+_vm._s(sessionClass.title)+" ")])}),1):_vm._e(),_c('el-popover',{attrs:{"placement":"top"},model:{value:(_vm.visible),callback:function ($$v) {_vm.visible=$$v},expression:"visible"}},[_c('p',[_vm._v("選擇報名流程")]),_c('p',[_c('router-link',{attrs:{"to":{ name: 'Arrange' }}},[_c('el-button',{staticStyle:{"width":"100%"},attrs:{"type":"primary","size":"mini"},on:{"click":function($event){_vm.visible = false}}},[_vm._v(" 分配團班 ")])],1)],1),_c('p',[_c('router-link',{attrs:{"to":{
                name: 'privateCreate',
                params: {
                  privateType: 'private-lessons'
                },
                query: {
                  enrolled_session_id: _vm.enrollment.id
                }
              }}},[_c('el-button',{staticStyle:{"width":"100%"},attrs:{"type":"primary","size":"mini"},on:{"click":function($event){_vm.visible = false}}},[_vm._v(" 建立家教課程 ")])],1)],1),_c('p',[_c('router-link',{attrs:{"to":{
                  name: 'privateCreate',
                  params: {
                    privateType: 'counseling'
                  },
                  query: {
                    enrolled_session_id: _vm.enrollment.id
                  }
                }}},[_c('el-button',{staticStyle:{"width":"100%"},attrs:{"type":"primary","size":"mini"},on:{"click":function($event){_vm.visible = false}}},[_vm._v(" 建立升學輔導 ")])],1)],1),_c('span',{attrs:{"slot":"reference"},slot:"reference"},[_c('i',{staticClass:"fas fa-edit action-icon"})])])],1)],2)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
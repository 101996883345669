<template>
  <div v-if="student_profile.basic_info.id !== undefined">
    <el-row>
      <el-button
        v-if="editable"
        plain
        type="success"
        icon="el-icon-edit"
        size="small"
        @click="editProfile(student_profile.basic_info.id)"
      >
        Edit
      </el-button>
    </el-row>
    <el-row>
      <!-- 學生姓名 -->
      <el-col :span="2" class="label">{{
        $t("enrollment.step2.student.name.label")
      }}</el-col>
      <el-col :span="22">
        <router-link
          :to="{
            name: 'ProfileForAdmin',
            params: { id: student_profile.basic_info.id }
          }"
        >
          {{
            userMethods.displayName(
              student_profile.basic_info.first_name,
              student_profile.basic_info.last_name,
              student_profile.basic_info.chinese_name
            )
          }}
        </router-link>
      </el-col>
    </el-row>
    <el-row>
      <!-- 學生 email -->
      <el-col :span="2" class="label">{{
        $t("enrollment.step2.student.email.label")
      }}</el-col>
      <el-col :span="22">
        {{ student_profile.basic_info.email }}
      </el-col>
    </el-row>
    <el-row>
      <!-- 學生電話 -->
      <el-col :span="2" class="label">{{
        $t("enrollment.step2.student.phone.label")
      }}</el-col>
      <el-col :span="22">
        {{ student_profile.basic_info.phone }}
      </el-col>
    </el-row>
    <el-row>
      <!-- 學生家裡電話 -->
      <el-col :span="2" class="label">{{
        $t("enrollment.step2.student.home.label")
      }}</el-col>
      <el-col :span="22">
        {{ student_profile.basic_info.home_phone }}
      </el-col>
    </el-row>
    <el-row>
      <!-- 學生地址 -->
      <el-col :span="2" class="label">{{
        $t("enrollment.step2.student.address.label")
      }}</el-col>
      <el-col :span="22">
        {{ student_profile.student.living }}
      </el-col>
    </el-row>
    <el-row>
      <!-- 學生學校 -->
      <el-col :span="2" class="label">{{
        $t("enrollment.step2.student.school.label")
      }}</el-col>
      <el-col :span="22">
        {{ student_profile.student.high_school }}
      </el-col>
    </el-row>
    <el-row>
      <!-- 學生畢業年份 -->
      <el-col :span="2" class="label">{{
        $t("enrollment.step2.student.grade.label")
      }}</el-col>
      <el-col :span="22">
        {{ getGrade(student_profile.student.high_school_graduation_year) }}
      </el-col>
    </el-row>
    <el-row>
      <!-- 學生考試成績 -->
      <el-col :span="2" class="label">{{
        $t("enrollment.detail.test")
      }}</el-col>
      <el-col :span="22">
        <div
          v-for="(testDetail, testName) in student_profile.testScores"
          :key="testName"
          class="mb-3"
        >
          <div class="font-weight-bold">
            {{ testName.toUpperCase() }}
            （{{ testDetail.taken_on }}）

            <small v-if="!testDetail.is_real_test">
              {{ $t("enrollment.detail.testTag") }}
            </small>
          </div>
          <span v-for="(score, item) in testDetail.scores" :key="item">
            {{ lodash.startCase(item) }}：{{ score }}／
          </span>
        </div>
      </el-col>
    </el-row>
  </div>
  <div v-else>
    <div v-if="editable">
      <!-- ===== 新增學生 ===== -->
      <el-form
        @submit.prevent.native="addStudent"
        :model="studentForm"
        :validate-on-rule-change="false"
        ref="studentForm"
        label-position="right"
        label-width="150px"
      >
        <!-- 學生姓名 -->
        <el-form-item
          :label="$t('enrollment.step2.student.name.label')"
          required
        >
          <div class="row">
            <div class="col-12 col-sm-6 col-md-4 pt-0">
              <el-form-item
                :rules="commonRules.input"
                prop="first_name"
                required
              >
                <el-input
                  v-model="studentForm.first_name"
                  :placeholder="
                    $t('enrollment.step2.student.name.placeholder.first')
                  "
                  required
                ></el-input>
              </el-form-item>
            </div>
            <div class="col-12 col-sm-6 col-md-4 pt-0">
              <el-form-item
                :rules="commonRules.input"
                prop="last_name"
                required
              >
                <el-input
                  v-model="studentForm.last_name"
                  :placeholder="
                    $t('enrollment.step2.student.name.placeholder.last')
                  "
                  required
                ></el-input>
              </el-form-item>
            </div>
            <div class="col-12 col-sm-6 col-md-4 pt-0">
              <el-form-item>
                <el-input
                  v-model="studentForm.chinese_name"
                  :placeholder="
                    $t('enrollment.step2.student.name.placeholder.other')
                  "
                ></el-input>
              </el-form-item>
            </div>
          </div>
        </el-form-item>
        <!-- 學生 email -->
        <el-form-item
          :label="$t('enrollment.step2.student.email.label')"
          :rules="[commonRules.input, commonRules.email]"
          prop="email"
          required
        >
          <el-input
            v-model="studentForm.email"
            type="email"
            :placeholder="$t('enrollment.step2.student.email.placeholder')"
            required
          ></el-input>
        </el-form-item>
        <!-- 學生電話 -->
        <el-form-item
          :label="$t('enrollment.step2.student.phone.label')"
          :rules="commonRules.input"
          prop="phone"
          required
        >
          <el-input
            v-model="studentForm.phone"
            type="tel"
            :placeholder="$t('enrollment.step2.student.phone.placeholder')"
            required
          ></el-input>
        </el-form-item>
        <!-- 學生家裡電話 -->
        <el-form-item :label="$t('enrollment.step2.student.home.label')">
          <el-input
            v-model="studentForm.home_phone"
            type="tel"
            :placeholder="$t('enrollment.step2.student.home.placeholder')"
          ></el-input>
        </el-form-item>
        <!-- 學生學校 -->
        <el-form-item
          :label="$t('enrollment.step2.student.school.label')"
          :rules="commonRules.input"
          prop="school"
          required
        >
          <el-input
            v-model="studentForm.school"
            :placeholder="$t('enrollment.step2.student.school.placeholder')"
            required
          ></el-input>
        </el-form-item>
        <!-- 學生畢業年份 -->
        <el-form-item :label="$t('enrollment.step2.student.grade.label')">
          <GradeLevelSelector
            :value="studentForm.graduation_year"
            @change="
              (value) => {
                studentForm.graduation_year = value;
              }
            "
          />
        </el-form-item>
        <!-- 學生地址 -->
        <el-form-item :label="$t('enrollment.step2.student.address.label')">
          <el-input
            v-model="studentForm.address"
            :placeholder="$t('enrollment.step2.student.address.placeholder')"
          ></el-input>
        </el-form-item>
        <!-- 儲存 -->
        <el-form-item class="text-right">
          <el-button native-type="submit" type="primary">
            {{ $t("enrollment.detail.saveButton") }}
          </el-button>
        </el-form-item>
      </el-form>
    </div>
    <div v-else>
      <p>暫無學生資料。</p>
    </div>
  </div>
</template>

<script>
import _ from "lodash";
import { helper, user } from "@ivy-way/material";
import { GradeLevelSelector } from "@/components/selector";
import formMixin from "@/mixins/form";
import profileTest from "@/mixins/profileTest";

import enrollmentApi from "@/apis/enrollment";

export default {
  components: {
    GradeLevelSelector
  },
  props: {
    enrollmentId: {
      type: String,
      default: null
    },
    editable: {
      type: Boolean,
      default: false
    },
    student_profile: {
      type: Object,
      default: () => ({})
    }
  },
  mixins: [formMixin, profileTest],
  data() {
    return {
      studentForm: {
        first_name: "",
        last_name: "",
        chinese_name: "",
        email: "",
        phone: "",
        home_phone: "",
        school: "",
        graduation_year: "",
        address: ""
      }
    };
  },
  computed: {
    lodash() {
      return _;
    },
    userMethods() {
      return user;
    }
  },
  watch: {},
  methods: {
    async addStudent() {
      const valid = await this.validateForm(this.$refs["studentForm"]);
      if (!valid) {
        return;
      }

      const form = JSON.parse(JSON.stringify(this.studentForm));

      await enrollmentApi.addStudent({
        enrolled_session_id: this.enrollmentId,
        student: {
          ...form
        }
      });

      this.$emit("refreshEnrollment");
    },
    editProfile(uid) {
      let routeUrl = this.$router.resolve({
        name: "ProfileForAdmin",
        params: { id: uid }
      });
      window.open(routeUrl.href, "_blank");
    }
  }
};
</script>

<style lang="scss" scoped>
</style>

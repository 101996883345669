<template>
  <div class="allEnrolledSessions">
    <el-card shadow="hover">
      <div class="userName">
        <i class="fas fa-folder"></i> {{ user }} （所有）
      </div>
      <el-table
        id="enrollment-table"
        :data="
          showEnrolledSessions.filter(enrolled => {
            return enrolled.status < 4;
          })
        "
        style="width: 100%"
        :row-class-name="tableRowClassName"
        @row-click="viewEnrolledSessions"
      >
        <el-table-column width="50">
          <template slot-scope="scope">
            <template v-if="scope.row.id == enrollmentId">
              <span><i class="fas fa-envelope-open"></i></span>
            </template>
            <template v-else>
              <span v-if="scope.row.is_read === 1">
                <i class="fas fa-envelope-open"></i>
              </span>
              <span v-if="scope.row.is_read === 0">
                <span class="text-success">
                  <i class="fas fa-envelope"></i>
                </span>
              </span>
            </template>
          </template>
        </el-table-column>
        <el-table-column :label="$t('enrollment.list.course')">
          <template slot-scope="scope">
            <span v-if="getCourseSessionTitle(scope.row)">
              {{
                `${getCourseSessionTitle(scope.row)} (${instant.formatDate(
                  scope.row.created_at
                )})`
              }}
            </span>
            <span v-else>
              **暫未報名成功，請補全！**
            </span>
          </template>
        </el-table-column>
        <el-table-column width="150" :label="$t('enrollment.list.date')">
          <template slot-scope="scope">
            {{ instant.formatDate(scope.row.created_at) }}
          </template>
        </el-table-column>
        <el-table-column width="150">
          <template slot-scope="scope" v-if="!(scope.row.id == enrollmentId)">
            <el-tooltip content="Delete" placement="top">
              <el-button
                plain
                type="danger"
                icon="el-icon-delete-solid"
                size="mini"
                @click.stop="deleteEnrollment(scope.row.id)"
              />
            </el-tooltip>
            <el-tooltip content="Edit" placement="top">
              <el-button
                @click="() => $emit('changeEdit')"
                plain
                type="success"
                icon="el-icon-edit-outline"
                size="mini"
              ></el-button>
            </el-tooltip>
          </template>
        </el-table-column>
      </el-table>
    </el-card>
  </div>
</template>

<script>
import { helper, instant } from "@ivy-way/material";
import { EnumEnrollmentStatuses } from "@/enums";
import enrollmentApi from "@/apis/enrollment";
import $ from "jquery";

export default {
  components: {},
  props: {
    user: {
      type: String,
      default: ""
    },
    enrollmentId: {
      type: [String, Number],
      default: null
    },
    filterStatus: {
      type: Number,
      default: EnumEnrollmentStatuses.read
    },
    showEnrolledSessions: {
      type: Array,
      default: () => []
    },
    studentEnrolledSessions: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {};
  },
  computed: {
    helper() {
      return helper;
    },
    instant() {
      return instant;
    },
    EnumEnrollmentStatuses() {
      return EnumEnrollmentStatuses;
    },
    unreadCount() {
      return this.studentEnrolledSessions.filter(({ is_read }) => !is_read)
        .length;
    },
    readCount() {
      return this.studentEnrolledSessions.filter(
        ({ status }) => status === this.EnumEnrollmentStatuses.read
      ).length;
    },
    archivedCount() {
      return this.studentEnrolledSessions.filter(
        ({ status }) => status === this.EnumEnrollmentStatuses.archived
      ).length;
    },
    canceledCount() {
      return this.studentEnrolledSessions.filter(
        ({ status }) => status === this.EnumEnrollmentStatuses.canceled
      ).length;
    },
    deletedCount() {
      return this.studentEnrolledSessions.filter(
        ({ status }) => status === this.EnumEnrollmentStatuses.deleted
      ).length;
    },
    ADD_NEW_PARENT() {
      return "ADD_NEW_PARENT";
    }
  },
  mounted() {},
  watch: {},
  methods: {
    async deleteEnrollment(id) {
      await enrollmentApi.setStatus({
        id: id,
        status: this.EnumEnrollmentStatuses.deleted
      });
      await this.$router.go(0);
    },
    tableRowClassName({ row, rowIndex }) {
      if (row.id == this.enrollmentId) {
        return "success-row";
      }
      if (row.is_read == 0) {
        return "unread-row";
      }
      if (row.is_read == 1) {
        return "read-row";
      }
      return "";
    },
    viewEnrolledSessions(row, column, event) {
      this.$emit("routeToEnrollmentSession", row.id);
    },
    getReadStatusText(status) {
      if (status === 0) {
        return this.$t("enrollment.list.readStatusList")[1];
      } else {
        return this.$t("enrollment.list.statusList")[status];
      }
    },
    getCourseSessionTitle(session) {
      if (session.course_session) {
        return this.helper.displayI18nText(
          this.$i18n.locale,
          session.course_session.title
        );
      } else if (session.custom_online) {
        return session.custom_online;
      } else if (session.custom_offline) {
        return session.custom_offline;
      }

      return null;
    }
  }
};
</script>

<style lang="scss" scoped>
::v-deep .el-table .success-row {
  background: #42a16a;
  color: white;
}
::v-deep .el-table .read-row {
}
::v-deep .el-table .unread-row {
  font-weight: 700;
  color: black;
}
::v-deep
  .el-table--enable-row-hover
  .el-table__body
  tr:hover
  > td.el-table__cell {
}
.allEnrolledSessions {
  margin-bottom: 20px;
}
::v-deep .el-table__row:hover {
  cursor: pointer;
}
.userName {
  font-size: 18px;
  font-weight: 700;
}
::v-deep .el-collapse {
  border: none;
}
::v-deep .el-card__body {
  padding: 20px;
}
::v-deep .el-collapse-item__header {
  padding: 0 20px;
}
::v-deep .el-badge {
  margin-bottom: -10px;
  margin-left: 8px;

  .el-badge__content {
    background: #aaa;
  }
}

::v-deep .warning {
  .el-badge__content {
    background: #d9534f;
  }
}
</style>
